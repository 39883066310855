<template>
    <div class="">
        <slot></slot>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'
export default {
  mixins: [pageMixin]
}
</script>
